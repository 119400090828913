import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";


// Define the initial state using that type
const initialState: any = {suggetions: [], results: []};

export const searchSlice = createSlice({
  name: "search",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSuggetions: (state, action: PayloadAction<any>) => {
      state.suggetions = action.payload;
    },
    setSearchResult: (state, action: PayloadAction<any>) => {
      state.results = action.payload;
    }
  },
});

export const { setSuggetions, setSearchResult } = searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getSuggetions = (state: RootState) => state.search.suggetions ?? ['whats'];
export const getSearchResults = (state: RootState) => state.search.results;

export default searchSlice.reducer;
