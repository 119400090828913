import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Avatar, Chip, Skeleton, Stack } from "@mui/material";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../utils/constants";
import { ChannelDetails } from "../../types/channel";
import { inscribeChannel } from "../../services/APIServices/user.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { isUserChannel } from "../../utils";

interface MediaProps {
  fullWidth?: boolean;
  isLoading?: boolean;
  data: ChannelDetails;
}

function truncateText(title: string, maxLength: number) {
  if (title) {
    if (title.length <= maxLength) {
      return title;
    }
    const words = title.split(" ");
    const truncatedWords = words.slice(0, maxLength);
    return truncatedWords.join(" ") + "...";
  }
  return "";
}

function truncateDescription(description: string, maxLength: number) {
  if (description) {
    if (description.length <= maxLength) {
      return description;
    }
    const words = description.split(" ");
    const truncatedWords = words.slice(0, maxLength);
    return truncatedWords.join(" ") + "...";
  }
  return "";
}

export default function ChannelCard({
  fullWidth = false,
  isLoading = false,
  data,
}: MediaProps) {
  const [channel, setChannel] = useState<ChannelDetails>(data);
  const [loading, setLoading] = useState(isLoading);
  const [gridProps, setGridProps] = useState({});
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();

  useEffect(() => {
    setChannel(data);
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isMobile && fullWidth)
      setGridProps({
        lg: 8,
        md: 8,
        sm: 8,
      });
    else if (isMobile)
      setGridProps({
        lg: 12,
        md: 12,
        sm: 12,
      });
    else setGridProps({});
  }, [isMobile, fullWidth]);

  const handleMenuOpen = (index?: number) => {
    // Handle menu open for the corresponding video
    console.log("Menu open for video at index:", index);
  };

  const handleInscribe = () => {
    inscribeChannel(channel?.channelID as string, user?.userID).then(
      (res: any) => {
        let status = res?.message?.split(" ")?.[0];
        if (status && channel) {
          setChannel({
            ...channel,
            isSubscribed: status as string,
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Box
        sx={{ overflow: "hidden" }}
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid
          container
          alignItems="center"
          justifyContent={"left"}
          sx={{ rowGap: "10px", columnGap: "10px" }}
        >
          <Grid
            {...gridProps}
            sx={{
              padding: 1,
            }}
          >
            <Card
              sx={{
                width: isMobile || fullWidth ? "100%" : 350,
                height: fullWidth ? "auto" : 300,
                position: "relative",
                display: "flex",
                flexDirection: fullWidth ? "row" : "column",
                justifyContent: "space-between",
                alignItems: "center",
                overflow: "hidden",
                cursor: "pointer",
                padding: 2,
                // background:
                //   " linear-gradient(90deg, rgba(234,75,46,1) 0%, rgba(255,155,62,1) 29%, rgba(250,250,250,1) 100%)",
              }}
            >
              <>
                <Box flex={2}>
                  {!isLoading ? (
                    <Avatar
                      alt="channel profile"
                      src={channel?.profilePic as string}
                      sx={{
                        width: 140,
                        height: 140,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          `${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`
                        )
                      }
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width={fullWidth ? 152 : "100%"}
                      height={fullWidth ? 152 : "50%"}
                    />
                  )}
                </Box>
                <Box flex={3} sx={{ marginLeft: 2, width: "100%" }}>
                  {!isLoading ? (
                    <Stack direction={"column"}>
                      <Typography
                        display="block"
                        variant="h6"
                        lineHeight={3}
                        color="text.secondary"
                        onClick={() =>
                          navigate(
                            `${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`
                          )
                        }
                      >
                        {channel?.name}
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="text.secondary"
                        onClick={() =>
                          navigate(
                            `${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`
                          )
                        }
                      >
                        {`@${channel?.channelTag} • ${
                          channel?.subscriberCount ?? "1.5M subscribers"
                        }`}
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="text.secondary"
                      >
                        {truncateDescription(
                          channel?.channelDescription ?? "",
                          8
                        )}
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="caption" color="text.secondary">
                          {`Published • ${moment(
                            `${channel?.creationTime
                              ?.split("T")
                              ?.join(" ")} UTC`
                          ).fromNow()}`}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </>
                  )}
                </Box>
                <Box flex={1}>
                  <Chip
                    size="medium"
                    color="secondary"
                    label={`${
                      (typeof channel?.isSubscribed === "boolean" &&
                        typeof channel?.isSubscribed) ||
                      channel?.isSubscribed === "SUBSCRIBED"
                        ? "Unsubscribe"
                        : "Subscribe"
                    }`}
                    sx={{ cursor: "pointer", marginInline: 2 }}
                    onClick={() => handleInscribe()}
                    disabled={isUserChannel(user?.channelList, channel.channelID)}
                  />
                </Box>
              </>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
