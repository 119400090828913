// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-wrapper {
  width: auto; 
  height: auto; 
}
.react-player {
  width: 100%;
  aspect-ratio: 16/9;
}

.absolute{
  right:0 !important;
  left: 0 !important;
}

.player-placeholder{
  position: relative;
  width: 100%;
  padding-bottom: 58.25%;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Player/VideoPlayer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".player-wrapper {\r\n  width: auto; \r\n  height: auto; \r\n}\r\n.react-player {\r\n  width: 100%;\r\n  aspect-ratio: 16/9;\r\n}\r\n\r\n.absolute{\r\n  right:0 !important;\r\n  left: 0 !important;\r\n}\r\n\r\n.player-placeholder{\r\n  position: relative;\r\n  width: 100%;\r\n  padding-bottom: 58.25%;\r\n  overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
