import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getUser, setUser } from "../store/reducers/user.reducer";
import { Box, Modal } from "@mui/material";
import UserLogin from "../pages/UserLogin/UserLogin";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "../utils/constants";
// Define the context
interface AuthContextType {
  verifyAuth: () => boolean;
  setOnLoginSuccess: any;
  onLoginSuccess: any;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// AuthProvider component
export const AuthProvider: React.FC = ({ children }: any) => {
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const onLoginSuccess = useRef<any>(null);
  const navigate = useNavigate();
  let dispatch = useAppDispatch();

  const logout = () => {
    dispatch(setUser({}));
    localStorage.setItem('token', '');
    navigate(ROUTES_PATH.LOGIN);
  }

  const verifyAuth = () => {
    let token = localStorage.getItem("token");
    if (token && token!==null && token!='') {
      return true;
    } else {
      setShowLoginPopup(true);
      return false;
    }
  };

  const setOnLoginSuccess = (successMethod:() => void) => {
    onLoginSuccess.current = successMethod;
  }

  const handleLogin = () => {
    setShowLoginPopup(false);
    onLoginSuccess?.current?.();
  };
  const handleClose = () => {
    setShowLoginPopup(false);
  }
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  return (
    <AuthContext.Provider
      value={{ verifyAuth, onLoginSuccess, setOnLoginSuccess, logout }}
    >
      {children}
      {showLoginPopup ? (
        // Your login popup component
        <Modal
          open={showLoginPopup}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <UserLogin redirectRequired={false} onSuccess={handleLogin} />
          </Box>
        </Modal>
      ) : null}
    </AuthContext.Provider>
  );
};
