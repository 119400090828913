import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Grid, Container } from "@mui/material";
import "./Profile.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUser, setUser } from "../../store/reducers/user.reducer";
import {
  getUserDetails,
  setProfilePic,
  updateUserDetails,
} from "../../services/APIServices/user.api";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "../../utils/constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface FormData {
  address: string;
  dob: Date | null | any;
  channelList: never[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
  profilePic: File | string | null;
  username: string;
}

const Profile = () => {
  const [formData, setFormData] = useState<FormData>({
    address: "",
    dob: null,
    channelList: [],
    email: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    profilePic: null,
    username: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  let user = useAppSelector((state) => getUser(state));
  let dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.username) {
      let { channelList, ...userData } = user;
      setFormData((prev) => ({ ...prev, ...userData }));
    } else
      navigate(ROUTES_PATH.LOGIN, {
        state: {
          redirectTo: ROUTES_PATH?.USER_PROFILE,
        },
      });
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prevState) => ({
      ...prevState,
      dob: date !== null ? date : null,
    }));
  };

  const handleProfilePicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("files", e.target.files);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file,
      }));
      setProfilePic(user?.userID, file).then((res) => {
        if (res?.data?.profilePic) {
          dispatch(setUser({ ...user, profilePic: res?.data?.profilePic }));
        }
      });
    }
  };

  const handleDeleteProfilePic = () => {
    setFormData((prevState) => ({
      ...prevState,
      profilePic: null,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    let { address, dob, email, firstName, lastName, phoneNo, username } =
      formData;
    if (dob) {
      dob = typeof dob==='string'?dob:dob.format('DD/MM/YYYY');
    }
    const payload = {
      address,
      dob,
      email,
      firstName,
      lastName,
      phoneNo,
      username,
    };
    updateUserDetails(user?.userID, payload).then((res:any) => {
      if (res?.statusCode===200) {
        dispatch(setUser({ ...user, ...payload}));
      }
    });
  };

  const resetProfile = () => {
    if (user) {
      let { channelList, ...userData } = user;
      setFormData((prev) => ({ ...prev, ...userData }));
    }
    setIsEditing(false);
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        User Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                position: "relative",
              }}
            >
              <label htmlFor="profile-pic">
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "inline-block",
                    border: "2px solid #ccc",
                  }}
                >
                  {formData.profilePic ? (
                    <img
                      src={
                        typeof formData.profilePic === "string"
                          ? formData.profilePic
                          : URL.createObjectURL(formData.profilePic)
                      }
                      alt="Profile"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "#ccc",
                      }}
                    >
                      <CloudUploadIcon fontSize="large" />
                    </div>
                  )}
                </div>
              </label>
              <input
                accept="image/*"
                id="profile-pic"
                type="file"
                onChange={handleProfilePicChange}
                style={{ display: "none" }}
              />
            </div>
          </Grid>
          {/* {formData.profilePic && (
            <Grid item xs={12}>
              <Button
                onClick={handleDeleteProfilePic}
                variant="outlined"
                color="secondary"
                style={{ width: "100%" }}
              >
                Delete Picture
              </Button>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              inputProps={{
                readOnly: !isEditing,
              }}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              inputProps={{
                readOnly: !isEditing,
              }}
              
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              inputProps={{
                readOnly: true,
              }}
              
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              inputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formData.dob? dayjs(formData.dob): dayjs()}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                label="Date of Birth (DD/MM/YYYY)"
                sx={{ width: "100%" }}
                disabled={!isEditing}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
              inputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              inputProps={{
                readOnly: !isEditing,
              }}
              disabled={!isEditing}
            />
          </Grid>
          {isEditing ? (
            <>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="reset"
                  variant="outlined"
                  color="primary"
                  onClick={() => resetProfile()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                fullWidth
                type="reset"
                variant="outlined"
                color="secondary"
                onClick={() => setIsEditing(true)}
              >
                Edit Profile
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default Profile;
