import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_URL;

export async function createUser(userData) {
  try {
    const response = await axios.post(`${baseURL}user/createUser`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function verifyUser(email, otp) {
  try {
    const response = await axios.post(`${baseURL}user/verifyUser`, null, {
      params: { email, otp }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
