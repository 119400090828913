import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { ChannelDetails } from "../../types/channel";


// Define the initial state using that type
const initialState: ChannelDetails | any = {};

export const channelSlice = createSlice({
  name: "channel",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setChannel: (state, action: PayloadAction<ChannelDetails | any>) => {
      state.value = action.payload;
    }
  },
});

export const { setChannel } = channelSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getChannel = (state: RootState) => state.channel.value;

export default channelSlice.reducer;
