import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createUser, verifyUser } from "./AuthSignUp";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import { setMessage } from "../../store/reducers/message.reducer";
import { setLoading } from "../../store/reducers/loading.reducer";

interface FormData {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phoneNo: string;
  password: string;
  confirmPassword: string;
  otp: string;
  dob: string;
  address: string;
}

function UserSignup() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const handleClickShowConfPassword = () =>
    setShowConfPassword((show) => !show);
  const [errors, setErrors] = useState<any>({
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    phoneNo: null,
    password: null,
    confirmPassword: null,
    otp: null,
    dob: null,
    address: null,
  });
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
    dob: "",
    address: "",
  });
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [signupDisabled, setSignupDisabled] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    setIsFormValid(validateForm(name, value));
  };

  const handleDateChange = (value: any) => {
    setFormData((prev: any) => ({ ...prev, dob: value.format("DD/MM/YYYY") }));
    setIsFormValid(validateForm("dob", value.format("DD/MM/YYYY")));
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validateForm = (field?: string, value?: any) => {
    let errors: any = {};
    let isValid = true;

    // Validation for First Name
    if (field === "firstName") {
      if (!value.trim()) {
        errors.firstName = "First Name is required";
        isValid = false;
      } else {
        errors.firstName = null;
      }
    }

    // Validation for Last Name
    if (field === "lastName") {
      if (!value.trim()) {
        errors.lastName = "Last Name is required";
        isValid = false;
      } else {
        errors.lastName = null;
      }
    }

    // Validation for Username
    if (field === "username") {
      if (!value.trim()) {
        errors.username = "Username is required";
        isValid = false;
      } else if (!/^[a-z]+$/.test(value)) {
        errors.username = "Username must contain only lowercase letters";
        isValid = false;
      } else {
        errors.username = null;
      }
    }

    // Validation for Email
    if (field === "email") {
      if (!value.trim()) {
        errors.email = "Email is required";
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        errors.email = "Email is invalid";
        isValid = false;
      } else {
        errors.email = null;
      }
    }

    // Validation for Phone Number
    if (field === "phoneNo") {
      if (!value.trim()) {
        errors.phoneNo = "Phone Number is required";
        isValid = false;
      } else if (!/^\d{10}$/.test(value)) {
        errors.phoneNo = "Phone Number is invalid";
        isValid = false;
      } else {
        errors.phoneNo = null;
      }
    }
    // Validation for Password
    if (field === "password") {
      if (!value.trim()) {
        errors.password = "Password is required";
        isValid = false;
      } else if (value.trim().length < 8) {
        errors.password = "Password must be at least 8 characters long";
        isValid = false;
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
          value
        )
      ) {
        errors.password =
          "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character";
        isValid = false;
      } else {
        errors.password = null;
      }
    }

    // Validation for Confirm Password
    if (field === "confirmPassword") {
      if (!value.trim()) {
        errors.confirmPassword = "Confirm Password is required";
        isValid = false;
      } else if (value.trim() !== formData.password.trim()) {
        errors.confirmPassword = "Passwords do not match";
        isValid = false;
      } else {
        errors.confirmPassword = null;
      }
    }

    // Validation for OTP
    // if (!formData.otp.trim()) {
    //   errors.otp = "OTP is required";
    //   isValid = false;
    // } else if (!/^\d{6}$/.test(formData.otp)) {
    //   errors.otp = "OTP must be a 6-digit number";
    //   isValid = false;
    // }

    // Validation for Date of Birth
    if (field === "dob") {
      if (!value.trim()) {
        errors.dob = "Date of Birth is required";
        isValid = false;
      } else {
        errors.dob = null;
      }
    }

    // Validation for Address
    if (field === "address") {
      if (!value.trim()) {
        errors.address = "Address is required";
        isValid = false;
      } else {
        errors.address = null;
      }
    }

    setErrors(errors);
    for (let data of Object.values(formData)) {
      console.log("data", data);
      if (!data) {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSignup = async () => {
    try {
      if (!isFormValid) {
        dispatch(setMessage("Please fill in all the fields."));
        return;
      }
      dispatch(setLoading({ isLoading: true }));

      const response = await createUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.username,
        email: formData.email,
        password: formData.password,
        phoneNo: formData.phoneNo,
        dob: formData.dob,
        address: formData.address,
        role: ["ADMIN"],
      });
      dispatch(setLoading({ isLoading: false }));
      console.log("Signup successful:", response);
      dispatch(setMessage("Signup successful"));
      setShowOTPInput(true);
    } catch (error: any) {
      console.error("Signup failed:", error.response.data.message);
      dispatch(setLoading({ isLoading: false }));
      dispatch(setMessage(error.response.data.message));
    }
  };

  const handleOTPVerification = async () => {
    try {
      const response = await verifyUser(formData.email, formData.otp);
      dispatch(setMessage(response.message ? response.message : response));
      navigate("/");
    } catch (error: any) {
      console.error("OTP verification failed:", error);
      dispatch(setMessage(error.response.message));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth={"sm"}>
      <Stack direction={"column"}>
        {!showOTPInput ? (
          <>
            <h4 style={{ textAlign: "center" }}>Create an account</h4>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginTop: 2 }}
                  variant="outlined"
                  label="First Name*"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  error={!isFormValid && errors?.firstName}
                  helperText={errors?.firstName ?? ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ marginTop: 2 }}
                  variant="outlined"
                  label="Last Name*"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  error={errors?.lastName}
                  helperText={errors?.lastName ?? ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <TextField
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Username*"
              placeholder="username"
              name="username"
              value={formData.username}
              error={errors?.username}
              helperText={errors?.username ?? ""}
              onChange={handleChange}
            />
          </>
        ) : null}
        <TextField
          sx={{ marginTop: 2 }}
          variant="outlined"
          label="Email*"
          placeholder="Email"
          name="email"
          value={formData.email}
          error={errors?.email}
          helperText={errors?.email ?? ""}
          onChange={handleChange}
        />
        {!showOTPInput ? (
          <>
            <TextField
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Phone Number*"
              placeholder="Phone Number"
              name="phoneNo"
              value={formData.phoneNo}
              error={errors?.phoneNo}
              helperText={errors?.phoneNo ?? ""}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Address*"
              placeholder="Address"
              name="address"
              value={formData.address}
              error={errors?.address}
              helperText={errors?.address ?? ""}
              onChange={handleChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                label="Date of Birth (DD/MM/YYYY)*"
                sx={{ width: "100%", marginTop: 2 }}
                slotProps={{
                  textField: {
                    error: errors?.address,
                    helperText: errors?.address ?? "",
                  },
                }}
                disableFuture
              />
            </LocalizationProvider>
            <TextField
              type={showPassword ? "text" : "password"}
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Password*"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={errors?.password}
              helperText={errors?.password ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showConfPassword ? "text" : "password"}
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Confirm Password*"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={errors?.confirmPassword}
              helperText={errors?.confirmPassword ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : null}
        {showOTPInput ? (
          <>
            <TextField
              variant="outlined"
              label="OTP"
              placeholder="Enter OTP"
              name="otp"
              value={formData.otp}
              onChange={handleChange}
            />
            <Button variant="contained" onClick={handleOTPVerification}>
              Verify OTP
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={handleSignup}
            disabled={!isFormValid}
            type={"submit"}
          >
            Signup
          </Button>
        )}
        <h6 style={{ marginTop: 10, textAlign: "center" }}>
          Already have an account?{" "}
          <span>
            <a href="/UserLogin">Login</a>
          </span>{" "}
          now.
        </h6>
      </Stack>
    </Container>
  );
}

export default UserSignup;
