import { Container } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Banner from '../../components/banner'
import ProfileCard from '../../components/channel-profile-card'
import TabBar from '../../components/TabBar/TabBar'
import { useAppSelector } from '../../store/hooks'
import { getChannelByID, getChannelVideos } from '../../services/APIServices/channel.api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChannelDetails } from '../../types/channel';
import { getUser } from '../../store/reducers/user.reducer'
import { isMobile } from 'react-device-detect'

interface ChannelProps {
    create?: boolean
}

export default function ChannelProfile({ create }: ChannelProps) {
    const [loading, setLoading] = useState(true);
    const [isExpanded, setExpanded] = useState(false);
    let [channel, setChannel] = useState<ChannelDetails | null>(null);
    let user = useAppSelector((state) => getUser(state));
    let [videos, setVideos] = useState(null);
    let { pathname } = useLocation();
    let { channelID } = useParams();
    let navigate = useNavigate();

    const getLoadingStatus = useCallback(() => channel ? false : true, [channel])

    useEffect(() => {
        setLoading(getLoadingStatus);
    }, [channel, getLoadingStatus])

    useEffect(() => {
        if (channelID) {
            getChannelByID(channelID, user?.userID).then((res: any) => setChannel(res))
            getChannelVideos(channelID).then((res: any) => setVideos(res))
        }
    }, [channelID, user])

    return (
        <div style={{ paddingLeft: isMobile ? 0 : "16px" }}>
            <Banner defaultImage={channel?.backgroundPic} isLoading={loading} viewOnly />
            <ProfileCard
                viewOnly
                channel={channel}
                isLoading={loading}
                handleExpansion={(v: boolean) => setExpanded(v)}
            />
            <TabBar viewOnly data={videos} isLoading={loading} />
        </div>
    )
}

