import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import SearchIcon from "@mui/icons-material/Search";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  Autocomplete,
  CssBaseline,
  Divider,
  Icon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { InputBaseProps } from "@mui/material/InputBase";
import Toolbar from "@mui/material/Toolbar";
import { alpha, styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES, ROUTES_PATH } from "../../utils/constants";
import Navbar from "../Drawer/Drawer";
import { isMobile } from "react-device-detect";
import {
  getAllSearchResults,
  getSearchSuggestions,
} from "../../services/APIServices/search.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getSuggetions,
  setSearchResult,
  setSuggetions,
} from "../../store/reducers/search.reducer";
// import './AppBar.scss';
import ashokaChakra from "../../assets/Ashoka_Chakra.svg";
import ashokaChakraBlue from "../../assets/Ashoka_Chakra_blue.svg";
import textLogoWhite from "../../assets/IndeeFlixLogo-white.png";
import logoOnly from "../../assets/IndeeFlix-logo-only.png";
import { ColorModeContext } from "../App/App";
import { useAuth } from "../../context/AuthContext";
import { getUser } from "../../store/reducers/user.reducer";
import ConfirmBtn from "../ConfirmBtn/ConfirmBtn";

const drawerWidth = 240;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  lineHeight: 0,
  borderRadius: 50,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  "&:focus": {
    borderRadius: 0,
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface CustomInputBasePros extends InputBaseProps {
  open?: boolean;
}

const StyledInputBase = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "open",
})<CustomInputBasePros>(({ theme, open }) => ({
  color: "inherit",
  "& .MuiOutlinedInput-root": {
    color: "inherit",
    border: 0,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50ch",
    height: "inherit",
    margin: "auto", // Center the input horizontally
    borderRadius: "50px", // Apply border radius of 10px
    [theme.breakpoints.up("md")]: {
      width: open ? `calc(80ch - ${drawerWidth}px)` : "60ch",
    },
    [theme.breakpoints.down("sm")]: {
      width: open ? "calc(100% - 48px)" : "calc(100% - 16px)",
      border: "0.5px solid #ccc",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "inherit",
    height: "inherit",
    border: 0,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50ch",
    margin: "auto", // Center the input horizontally
    borderRadius: "50px", // Apply border radius of 10px
    [theme.breakpoints.up("md")]: {
      width: open ? `calc(80ch - ${drawerWidth}px)` : "60ch",
    },
    [theme.breakpoints.down("sm")]: {
      width: open ? "calc(100% - 48px)" : "calc(100% - 16px)",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const [open, setOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // navigate("/userLogin");
  };
  const { logout } = useAuth();
  let user = useAppSelector((state) => getUser(state));

  const handleClose = () => {
    setAnchorEl(null);
  };

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //   marginLeft: drawerWidth,
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   transition: theme.transitions.create(["width", "margin"], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // }),
  }));

  const handleLogout = () => {
    logout();
  };

  const handleLogin = () => {
    navigate(ROUTES_PATH.LOGIN);
  };

  const handleProfile = () => {
    navigate("profile/" + user?.userID);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div id="App-Bar">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" color="primary" open={open}>
          <Toolbar>
            {!isMobile ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                // sx={{ mr: 2, ...(open && { display: "none" }) }}
                sx={{ mr: 2 }}
              >
                <Icon onClick={handleDrawer}>
                  <img
                    height="100%"
                    src={
                      theme.palette.mode === "dark"
                        ? ashokaChakraBlue
                        : ashokaChakra
                    }
                    alt=""
                    style={{ verticalAlign: "unset" }}
                  />
                </Icon>
              </IconButton>
            ) : null}
            <IconButton size="large" onFocus={(e) => e.target.blur()}>
              <Icon onClick={() => navigate(PAGE_ROUTES.HOME)}>
                <img
                  height="100%"
                  width="100%"
                  src={logoOnly}
                  alt=""
                  style={{ verticalAlign: "unset", outline: "none" }}
                />
              </Icon>
              <Icon
                onClick={() => navigate(PAGE_ROUTES.HOME)}
                style={{ overflow: "visible" }}
              >
                <img
                  height="100%"
                  src={textLogoWhite}
                  alt=""
                  style={{ verticalAlign: "unset", outline: "none" }}
                />
              </Icon>
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Search className="search-container">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <SearchInput />
              </Search>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {isMobile ? (
                  <IconButton
                    size="large"
                    edge="end"
                    aria-haspopup="true"
                    onClick={() => setSearchOpen(!searchOpen)}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    color="inherit"
                  >
                    <SearchIcon />
                  </IconButton>
                ) : null}
              </Box>
              <IconButton
                size="large"
                edge="end"
                aria-haspopup="false"
                onClick={colorMode.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                size="large"
                edge="end"
                aria-haspopup="true"
                color="inherit"
              >
                <NotificationsRoundedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                id="profile-button"
                size="large"
                aria-label="account of current user"
                aria-controls={"profile-menu"}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="profile-menu"
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: window.innerWidth }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                aria-labelledby="profile-button"
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {user && user.username ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        paddingInline: "12px",
                        paddingBottom: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          maxWidth: "200px",
                          minWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${user?.firstName} ${user?.lastName}`.length > 15
                          ? `${user?.firstName} ${user?.lastName}`.substring(
                              0,
                              15
                            ) + "..."
                          : `${user?.firstName} ${user?.lastName}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.email.length > 15
                          ? user?.email.substring(0, 15) + "..."
                          : user?.email}
                      </Typography>
                    </Stack>
                    <Divider />
                    {/* <MenuItem onClick={handleProfile}>Profile</MenuItem> */}

                    <ConfirmBtn
                      onConfirm={handleLogout}
                      confirmationMessage="Are you sure you want to logout?"
                      confirmButtonText="Yes, Logout"
                      cancelButtonText="Cancel"
                    >
                      <Typography variant="button" color="secondary">
                        Logout
                      </Typography>
                    </ConfirmBtn>
                  </Box>
                ) : (
                  <MenuItem onClick={handleLogin}>
                    <Typography variant="button" color="primary">
                      Login
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Navbar isMenuOpen={open} handleDrawerClose={handleDrawer} />
      </Box>
      {searchOpen && <SearchInput />}
    </div>
  );
}

const SearchInput = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let suggetionReq = useRef<any>(null);
  const [suggetions, setSuggetions] = useState<any>([]);

  const handleInput = (value: string) => {
    if (value.length >= 3) {
      if (suggetionReq?.current) clearTimeout(suggetionReq?.current);
      suggetionReq.current = setTimeout(() => {
        getSearchSuggestions(0, 10, value?.trim()).then((res: any) => {
          setSuggetions(res);
        });
      }, 600);
    }
  };

  const handleSearch = (e: any, value: string) => {
    e?.preventDefault();
    if (suggetionReq?.current) clearTimeout(suggetionReq?.current);
    suggetionReq.current = setTimeout(() => {
      getAllSearchResults(0, 10, value?.trim())
        .then((res: any) => {
          dispatch(setSearchResult(res));
          navigate(PAGE_ROUTES.SEARCH_PAGE);
        })
        .catch(() => {
          dispatch(setSearchResult([]));
        });
    }, 600);
  };
  return (
    <Autocomplete
      freeSolo
      autoComplete
      autoHighlight
      options={suggetions}
      onChange={(e: any, v: any) => {
        handleSearch(e, v);
      }}
      renderInput={(params: any) => (
        <StyledInputBase
          {...params}
          onChange={(e) => handleInput(e.target.value)}
          placeholder="Search…"
          InputProps={{
            ...params.InputProps,
            type: "search",
            "aria-label": "search",
          }}
        />
      )}
    />
  );
};
