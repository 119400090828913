import React, { useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box, Skeleton } from "@mui/material";

import defaultImg from "../../assets/defaultImg.jpg";

interface BannerProps {
  viewOnly?: boolean;
  aspectRatio?: string;
  defaultImage: string | File | any;
  isLoading?: boolean;
  handleUploadImage?: (file: File) => void;
}

export default function Banner({
  viewOnly = false,
  aspectRatio = "1024/250",
  defaultImage = defaultImg,
  isLoading = false,
  handleUploadImage = () => { },
}: BannerProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const fileInputRef = useRef<any>(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef?.current?.click(); // Trigger the file input click event
      }
    }, 0);
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setUploadedImage(e?.target?.result);
    };

    reader.readAsDataURL(file);
    handleUploadImage(file);
    setIsEditing(false);
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    handleUploadImage(defaultImage);
  };

  const handleCancelUpload = () => {
    setIsEditing(false);
    setUploadedImage(null);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{width:"100"}}>
        <Card
          sx={{
            display: "flex",
            aspectRatio: aspectRatio,
            maxHeight: "100%",
            position: "relative",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={"100%"} height={"100%"} />
          ) : (
            <>
              {/* Edit Icon */}
              {!viewOnly && !isEditing && !uploadedImage && (
                <EditIcon
                  onClick={handleEditClick}
                  sx={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    zIndex: 2,
                    cursor: "pointer",
                    border: "1px solid currentColor", // Apply border with current color
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                />
              )}

              {/* Delete Icon */}
              {uploadedImage && (
                <DeleteIcon
                  onClick={handleRemoveImage}
                  sx={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    zIndex: 2,
                    cursor: "pointer",
                    border: "1px solid currentColor", // Apply border with current color
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                />
              )}

              {/* Card Media (Image) */}
              <CardMedia
                component="img"
                height="auto"
                width="100%"
                sx={{ objectFit: "cover", width: "100%", height: "auto" }}
                src={uploadedImage || defaultImage}
                alt="Channel's Banner"
              />

              {/* Image Upload input when editing */}
              {!viewOnly && isEditing && (
                <React.Fragment>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <CancelIcon
                    onClick={handleCancelUpload}
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "30px",
                      zIndex: 2,
                      cursor: "pointer",
                      border: "1px solid currentColor", // Apply border with current color
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                  />
                </React.Fragment>
              )}
            </>
          )}
        </Card>
      </Box>
    </React.Fragment>
  );
}
