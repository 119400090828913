import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";


// Define the initial state using that type
const initialState: any = {message: null};

export const messageSlice = createSlice({
  name: "message",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMessage: (state, action: PayloadAction<any>) => {
      console.log(action.payload)
      state.message = action.payload;
    }
  },
});

export const { setMessage } = messageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getMessage = (state: RootState) => state.message.message;

export default messageSlice.reducer;
