import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { uploadVideo } from "../../services/APIServices/channel.api";
import { VideoWrapper } from "../../types/channel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { setMessage } from "../../store/reducers/message.reducer";
import { setLoading } from "../../store/reducers/loading.reducer";
import { updateVideoDetail } from "../../services/APIServices/videos.api";

const suggestedCategories = [
  { tags: "Comedy" },
  { tags: "Gaming" },
  { tags: "Music" },
  { tags: "Education" },
  { tags: "Entertainment" },
  { tags: "Video/Animation" },
];

interface UploadVideoProps {
  defaultOpen?: boolean;
  width?: number | string;
  height?: number | string;
  onModalClose?: () => void;
  isEditing?: boolean;
  details?: any;
  onEditSuccess?: () => void;
}

const initialValue = {
  title: "",
  description: "",
  channelId: "",
  userID: "",
  ageLimit: 0,
  category: "",
};

const UploadVideo: React.FC<UploadVideoProps> = (props) => {
  const {
    width,
    height,
    onModalClose = () => {},
    defaultOpen = false,
    isEditing,
    details,
    onEditSuccess,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const thumbnailRef = useRef<HTMLInputElement>(null);

  const [source, setSource] = useState<string | undefined>();
  const [thumbnailSource, setThumbnailSource] = useState<string | undefined>();
  const [formData, setFormData] = useState<VideoWrapper>(initialValue);
  const [isCancelled, setIsCancelled] = useState(false);
  const [open, setOpen] = React.useState(defaultOpen);

  const user = useAppSelector((state) => getUser(state));
  const [uploading, setUploading] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setOpen(defaultOpen);

    return () => {
      setOpen(false);
    };
  }, [defaultOpen]);

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        userID: user.userID,
        channelId: user.channelList?.[0]?.channelID,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (isEditing) {
      setThumbnailSource(details?.thumbnailPic);
      setFormData({
        title: details.title,
        description: details.description,
        channelId: details.channelId,
        userID: details.userID,
        ageLimit: details.ageLimit,
        category: details.category,
      });
    }
  }, [isEditing, details]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setSource(url);
      setFormData((prev) => ({ ...prev, title: file.name?.split(".")[0] }));
    }
  };

  const handleThumbnailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const url = URL.createObjectURL(file);
      setThumbnailSource(url);
    }
  };

  const handleOpen = () => {
    setIsCancelled(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSource(undefined);
    setThumbnailSource(undefined);
    setFormData(initialValue);
    onModalClose();
  };

  const onUploadProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    console.log("progessEvent", progressEvent);
    dispatch(setLoading({ isLoading: true, progress: progress }));
  };

  const handleUpload = async () => {
    if (isCancelled) return;
    if (!formData.title) return;

    const videoFile = inputRef.current?.files?.[0];
    const thumbnailFile = thumbnailRef.current?.files?.[0];
    if (thumbnailSource) {
      setFormData((prev) => ({
        ...prev,
        thumbnailFileLocation: thumbnailSource,
      }));
    }
    // if (!videoFile || !thumbnailFile) {
    //   throw new Error("Please select both video and thumbnail.");
    // }

    // const formData = new FormData();
    // formData.append("video", videoFile);
    // formData.append("thumbnail", thumbnailFile);
    // formData.append("title", titleValue);
    // formData.append("description", descriptionValue);
    // formData.append("channelID", channelID);
    // formData.append("userID", userID);

    // const response = await fetch("API_ENDPOINT_URL", { // API Endpoint put kr do aur formData bhi check kr lena kya send kr rha
    //   method: "POST",
    //   body: formData,
    // });

    // if (!response.ok) {
    //   throw new Error("Error uploading video");
    // }
    setUploading(true);
    uploadVideo(videoFile, thumbnailFile, formData, onUploadProgress)
      .then((res) => {
        handleClose();
        setUploading(false);
        dispatch(setLoading({ isLoading: false, progress: 0 }));
        dispatch(setMessage("Video Uploaded Successfully"));
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
        dispatch(setLoading({ isLoading: false, progress: 0 }));
        dispatch(setMessage("Something went wrong, please try again!"));
      });
  };
  const handleUpdate = async () => {
    updateVideoDetail({
      ageLimit: formData?.ageLimit,
      category: [formData?.category],
      description: formData?.description,
      title: formData?.title,
      userID: user?.userID,
      videoID: details?.videoID,
    }).then((res: any) => {
      if (res?.success) {
        console.log("Video details updated successfully:", res.message);
      } else {
        console.log("Failed to update video details:", res?.message);
      }
      onEditSuccess?.();
    });
  };

  return (
    <div className="VideoInput">
      <Dialog
        open={open}
        onClose={handleClose}
        className="Modal"
        fullScreen
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            margin: "auto",
            padding: "15px",
          },
        }}
      >
        <DialogTitle>{"Upload Video"}</DialogTitle>
        <DialogContent dividers>
          <Box>
            <div className="ScrollableContainer">
              <Stack direction={"column"}>
                {!isEditing ? (
                  <>
                    <Box
                      style={{
                        marginTop: "20px",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100%",
                        maxWidth: "600px",
                        margin: "auto",
                      }}
                    >
                      <label htmlFor="videoInput">
                        <b>Video File </b>
                      </label>
                      <Divider />
                      <input
                        style={{
                          marginTop: "20px",
                          padding: "8px",
                          borderRadius: "5px",
                          width: "100%",
                          maxWidth: "600px",
                          margin: "auto",
                        }}
                        ref={inputRef}
                        id="videoInput"
                        className="VideoInput_input"
                        type="file"
                        onChange={handleFileChange}
                        accept="video/*"
                      />
                      {source && (
                        <video
                          style={{ marginTop: "5px",
                          maxHeight: "400px",
                          width: "100%", }}
                          className="VideoInput_video"
                          width="100%"
                          height={height}
                          controls
                          src={source}
                        />
                      )}
                    </Box>
                  </>
                ) : null}
                {!isEditing ? (
                  <>
                    <Box
                      style={{
                        marginTop: "20px",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100%",
                        maxWidth: "600px",
                        margin: "auto",
                      }}
                    >
                      <label htmlFor="thumbnailInput">
                        <b>Video Thumbnail </b>
                      </label>
                      <Divider />
                      <input
                        style={{
                          marginTop: "20px",
                          padding: "8px",
                          borderRadius: "5px",
                          width: "100%",
                          maxWidth: "600px",
                          margin: "auto",
                        }}
                        ref={thumbnailRef}
                        id="thumbnailInput"
                        className="ThumbnailInput_input"
                        type="file"
                        onChange={handleThumbnailChange}
                        accept="image/*"
                      />
                      {thumbnailSource && (
                        <img
                          className="ThumbnailInput_image"
                          src={thumbnailSource}
                          alt="Thumbnail"
                          style={{
                            marginTop: "16px",
                            maxHeight: "300px",
                            aspectRatio: "16/9",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      )}
                    </Box>
                  </>
                ) : null}
                <TextField
                  size="small"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={formData.title}
                  onChange={(e: any) =>
                    setFormData((prev) => ({ ...prev, title: e.target.value }))
                  }
                  style={{
                    margin: "20px auto 0px",
                    maxWidth: "600px",
                  }}
                />

                <TextField
                  size="small"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={formData.description}
                  onChange={(e: any) =>
                    setFormData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  style={{
                    margin: "20px auto 0px",
                    maxWidth: "600px",
                  }}
                />

                <Autocomplete
                  size="small"
                  multiple={false}
                  // freeSolo
                  style={{
                    width: "100%",
                    margin: "20px auto 0px",
                    maxWidth: "600px",
                  }}
                  id="tags-categories"
                  onInputChange={(e, v) => {
                    setFormData((prev) => ({ ...prev, category: v }));
                    console.log("v", v);
                  }}
                  options={suggestedCategories.map((option) => option.tags)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      placeholder="Categories"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  size="small"
                  label="Age Limit"
                  variant="outlined"
                  type="number"
                  defaultValue={0}
                  fullWidth
                  value={formData.ageLimit}
                  onChange={(e: any) =>
                    setFormData((prev) => ({
                      ...prev,
                      ageLimit: e.target.value,
                    }))
                  }
                  style={{
                    margin: "20px auto 0px",
                    maxWidth: "600px",
                  }}
                />
              </Stack>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color={"secondary"}
            size="medium"
            onClick={handleClose}
            disabled={uploading}
          >
            CANCEL
          </Button>
          {isEditing ? (
            <Button
              variant="contained"
              size="medium"
              onClick={handleUpdate}
              disabled={!formData.title || uploading}
            >
              UPDATE
            </Button>
          ) : (
            <Button
              variant="contained"
              size="medium"
              onClick={handleUpload}
              disabled={
                !inputRef.current?.files?.[0] || !formData.title || uploading
              }
            >
              UPLOAD
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadVideo;
