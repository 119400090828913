import { Button, Container, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { getUserDetails } from "../services/APIServices/user.api";
import { useAppDispatch } from "../store/hooks";
import { setUser } from "../store/reducers/user.reducer";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [userID, setUserID] = useState("");
  let dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogin = () => {
    if (userID)
      getUserDetails(userID).then((res) => {
        dispatch(setUser(res));
        navigate(-1);
      });
  };
  return (
    <Container maxWidth={"sm"}>
      <Stack direction={"column"}>
        <TextField
          variant="outlined"
          label="User ID"
          placeholder="Please enter your userID"
          onChange={(e) => setUserID(e.target.value)}
        />
        <Button
          disabled={!userID}
          variant="contained"
          onClick={() => handleLogin()}
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
      </Stack>
    </Container>
  );
};
export default Login;
