import React, { useEffect, useState } from "react";
import VideosCard from "../components/video-card/index";
import { getHomePageVideos } from "../services/APIServices/videos.api";
import { useAppSelector } from "../store/hooks";
import { getUser } from "../store/reducers/user.reducer";
import SuggestionsTab from "../components/SuggestionsTab/SuggestionsTab";
import { Container } from "@mui/material";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { videoUpperLimit } from "../utils/constants";

export default function Home() {
  const [videos, setVideos] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const user = useAppSelector((state) => getUser(state));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cancelToken, setCancelToken] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    getHomePageVideos({
      lowerLimit: 0,
      upperLimit: 10,
      userID: user?.userID,
      category: selectedCategory,
      cancelToken: cancelTokenSource.token,
    })
      .then((response: any) => {
        if (response?.length >= 0) {
          setVideos(response);
          setHasNext(response?.length === videoUpperLimit);
          setIsLoading(false);
        }
      })
      .catch((err) => console.error(err));
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Request cancelled");
      }
    };
  }, [user?.userID, selectedCategory]);

  const loadMore = () => {
    if (!isLoading && hasNext) {
      setIsLoading(true);
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      getHomePageVideos({
        lowerLimit: videos?.length,
        upperLimit: videoUpperLimit,
        userID: user?.userID,
        category: selectedCategory,
        cancelToken: cancelTokenSource.token,
      })
        .then((response: any) => {
          if (response?.length>=0) {
            setVideos(response);
            setHasNext(response?.length === videoUpperLimit);
            setIsLoading(false);
          }
        })
        .catch((err) => console.error(err));
      return () => {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Request cancelled");
        }
      };
    }
  };

  return (
    <Container>
      <SuggestionsTab
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <VideosCard isLoading={isLoading} data={videos} loadMore={loadMore} />
    </Container>
  );
}
