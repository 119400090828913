import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import VideosCard from "../../components/video-card/index";
import { useAppSelector } from "../../store/hooks";
import { getSearchResults } from "../../store/reducers/search.reducer";
import { RootState } from "../../store";
import { SearchElement } from "../../types/search";
import { Container, Divider, TextField } from "@mui/material";
import ChannelCard from "../../components/channel-profile-card/ChannelCard";
import { getChannelVideos } from "../../services/APIServices/channel.api";

export default function SearchPage() {
  const data = useAppSelector((state: RootState) => getSearchResults(state));

  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={{ margin: "15px 0px 10px 0px" }}>
        Search Results...
      </Typography>
      <Divider style={{ margin: "15px 0px 10px 0px", visibility: "inherit" }} />
      {data?.length ? (
        data?.map((ele: SearchElement) => GetElementByType(ele.type, ele))
      ) : (
        <Typography variant={"h6"}>No Result Found!</Typography>
      )}
    </Container>
  );
}

const GetElementByType = (type: string, details: SearchElement) => {
  const [channelVideos, setChannelVideos] = useState([]);
  useEffect(() => {
    if (details?.channel?.channelID) {
      getChannelVideos(details?.channel?.channelID).then((res: any) => {
        if (res) {
          setChannelVideos(res);
        }
      });
    }
  }, [details]);
  const getElement = () => {
    switch (type) {
      case "Video":
        return <VideosCard sx={{ width: "100%" }} data={[details?.video]} />;
      case "Channel":
        return (
          <Container>
            <ChannelCard data={details?.channel} fullWidth />
            <VideosCard sx={{ width: "100%" }} data={channelVideos} />
          </Container>
        );
      default:
        return <Typography variant={"h6"}>No Result Found!</Typography>;
    }
  };
  return type && details ? (
    getElement()
  ) : (
    <Typography variant={"h6"}>No Result Found!</Typography>
  );
};
