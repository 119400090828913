import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { UserDetails } from "../../types/user";


// Define the initial state using that type
const initialState: UserDetails | any = {};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<UserDetails | any>) => {
      state.value = action.payload;
    }
  },
});

export const { setUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState): UserDetails => state.user.value;

export default userSlice.reducer;
