import { ApiHelper } from "../heplers/APIHelper";
const apiHelper = new ApiHelper();

export const getUserDetails = (userID: string) => {
    return apiHelper.post(`user/getUserProfile?userID=${userID}`, {});
}
export const updateUserDetails = (userID: string, data: any) => {
    return apiHelper.post(`user/updateUser?userID=${userID}`, data);
}
export const inscribeChannel = (channelID: string, userID: string) => {
    return apiHelper.update(`user/inscribeChannel?channelID=${channelID}&userID=${userID}`);
}
export const setProfilePic = (userID: string, profileImg: File) => {
    return apiHelper.upload(`user/setProfilePic`, {userID, profileImg});
}