import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
  }
});

export async function signIn(formData) {
  try {
    const response = await axiosInstance.post("user/signIn", formData);
    axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function verifyUser(email, otp) {
  try {
    const response = await axiosInstance.post('user/verifyUser', { email, otp });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default axiosInstance;
