import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Chip, Grid, MenuItem, Select, Skeleton, Stack, Typography } from "@mui/material";
import { Report, Shortcut, ThumbDown, ThumbUp } from "@mui/icons-material";
import { RWebShare } from "react-web-share";
import { isMobile } from "react-device-detect";
import axios from "axios";

import "../Player/VideoPlayer.css";
import ReactPlayer from "react-player";
import {
  dislikeVideo,
  getVideoDetailsByID,
  likeVideo,
  reportVideo,
} from "../../services/APIServices/videos.api";
import { getChannelByID } from "../../services/APIServices/channel.api";
import { inscribeChannel } from "../../services/APIServices/user.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { PAGE_ROUTES } from "../../utils/constants";
import { isUserChannel } from "../../utils";
import { VideoResponse } from "../../types/medial";
import { ChannelDetails } from "../../types/channel";
import { useAuth } from "../../context/AuthContext";

const baseURL = process.env.REACT_APP_SERVER_URL;

function VideoPlayer(props: any) {
  const { videoDetails, setVideoDetails, channel, setChannel } = props;
  let { videoID } = useParams();

  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const player = useRef<any>();
  const [quality, setQuality] = useState("360p");
  const [appBarHeight, setAppBarHeight] = useState<any>(10);
  const [playerHeight, setPlayerHeight] = useState<any>(720);
  const [playerWidth, setPlayerWidth] = useState<any>(1280);
  const { verifyAuth, setOnLoginSuccess } = useAuth();

  useEffect(() => {
    setAppBarHeight(document.getElementById("App-Bar")?.offsetTop);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (videoDetails !== null && channel !== null) {
      setLoading(false);
    }
  }, [videoDetails]);

  const handleInscribe = () => {
    inscribeChannel(channel?.channelID as string, user?.userID).then(
      (res: any) => {
        let status = res?.message?.split(" ")?.[0];
        if (status && channel) {
          getChannelByID(videoDetails?.channelID as string, user?.userID).then(
            (res: any) => {
              setChannel(res);
              setLoading(false);
            }
          );
        }
      }
    );
  };

  const handleSubscribeClick = () => {
    if (verifyAuth()) {
      handleInscribe();
    } else {
      setOnLoginSuccess(handleInscribe);
    }
  };

  const handleDislike = () => {
    dislikeVideo(user?.userID as string, videoDetails?.videoID as string).then(
      (res: any) => {
        getVideoDetailsByID(videoDetails?.videoID as string).then(
          (res: any) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleDislikeClick = () => {
    if (verifyAuth()) {
      handleDislike();
    } else {
      setOnLoginSuccess(handleDislike);
    }
  };
  const handleReport = () => {
    reportVideo(user?.userID as string, videoDetails?.videoID as string).then(
      (res: any) => {
        getVideoDetailsByID(videoDetails?.videoID as string).then(
          (res: any) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleSpamClick = () => {
    if (verifyAuth()) {
      handleReport();
    } else {
      setOnLoginSuccess(handleReport);
    }
  };
  const handleLike = () => {
    likeVideo(user?.userID as string, videoDetails?.videoID as string).then(
      (res: any) => {
        getVideoDetailsByID(videoDetails?.videoID as string).then(
          (res: any) => {
            setVideoDetails(res);
          }
        );
      }
    );
  };
  const handleLikeClick = () => {
    if (verifyAuth()) {
      handleLike();
    } else {
      setOnLoginSuccess(handleLike);
    }
  };
  const handlePlayerReady = () => {
    setPlayerHeight(document.getElementById("player-wrapper")?.offsetHeight);
    setPlayerWidth(document.getElementById("player-wrapper")?.offsetWidth);
    setLoading(false);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleQualityChange = (event: any) => {
    const newQuality = event.target.value;
    setQuality(newQuality);
    player.current.seekTo(0); // Reset video to beginning when quality changes
  };

  const handleChannelClick = () => {
    if (channel?.channelID) {
      navigate(`${PAGE_ROUTES.CHANNEL_PAGE}/profile/${channel?.channelID}`);
    }
  };

  return (
    <>
      <div onContextMenu={(e) => e.preventDefault()}>
        <div
          id={"player-wrapper"}
          className={`player-wrapper ${isMobile ? "fixed-top" : ""}`}
          style={{
            top: (appBarHeight - 25) as any,
            backgroundColor: "black",
            zIndex: 999,
          }}
        >
          <ReactPlayer
            ref={player}
            onReady={() => handlePlayerReady()}
            className={`react-player ${isMobile ? "absolute" : ""}`}
            controls={true}
            playing={true}
            stopOnUnmount={true}
            url={`${baseURL}video/getVideo?watch=${videoID}&quality=${quality}`}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            width={"100%"}
            height={"100%"}
          />
          {/* <div style={{zIndex:1000, position: "relative", top: 10, right: 10 }}>
            <Select
              value={quality}
              onChange={handleQualityChange}
              variant="standard"
            >
              <MenuItem value="360p">360p</MenuItem>
              <MenuItem value="720p">720p</MenuItem>
              <MenuItem value="1080p">1080p</MenuItem>
            </Select>
          </div> */}
        </div>
        {isMobile ? (
          <div
            className={"player-placeholder"}
            style={{
              paddingBottom: `${100 / (playerWidth / playerHeight)}%`,
            }}
          />
        ) : null}
        {!loading ? (
          <div>
            <Stack>
              <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                {videoDetails?.title}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ color: "GrayText" }}
              >
                {`${videoDetails?.views ? videoDetails?.views : 0} views`}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", marginTop: 2 }}
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={12} md={7}>
                  <Stack direction={"row"} sx={{ alignItems: "center" }}>
                    <Avatar
                      alt={""}
                      src={channel?.profilePic as string}
                      sx={{
                        width: 56,
                        height: 56,
                        marginRight: 2,
                        cursor: "pointer",
                      }}
                      onClick={handleChannelClick}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        onClick={handleChannelClick}
                        sx={{ cursor: "pointer" }}
                      >
                        {channel?.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: "GrayText" }}
                      >
                        {`${
                          channel?.subscriberCount
                            ? channel?.subscriberCount
                            : 0
                        } subscribers`}
                      </Typography>
                    </Stack>
                    <Chip
                      size="medium"
                      color="secondary"
                      label={`${
                        (typeof channel?.isSubscribed === "boolean" &&
                          typeof channel?.isSubscribed) ||
                        channel?.isSubscribed === "SUBSCRIBED"
                          ? "Unsubscribe"
                          : "Subscribe"
                      }`}
                      sx={{
                        cursor: "pointer",
                        marginInline: 2,
                        marginLeft: "auto",
                        right: 0,
                      }}
                      onClick={() => handleSubscribeClick()}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} sx={{ alignItems: "center" }}>
                  <Stack
                    direction={"row"}
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                    gap={"8px"}
                  >
                    <Chip
                      icon={<ThumbUp />}
                      label={videoDetails?.likes}
                      onClick={() => {
                        handleLikeClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                      // sx={{ marginInline: "1rem" }}
                    />
                    <Chip
                      icon={<ThumbDown />}
                      label={videoDetails?.disLikes}
                      onClick={() => {
                        handleDislikeClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                      // sx={{ marginInline: "1rem" }}
                    />
                    <Chip
                      icon={<Report />}
                      label={"Spam"}
                      onClick={() => {
                        handleSpamClick();
                      }}
                      disabled={isUserChannel(
                        user?.channelList,
                        channel?.channelID
                      )}
                      // sx={{ marginInline: "1rem" }}
                    />
                    <RWebShare
                      data={{
                        text: videoDetails?.description,
                        url: window.location.href,
                        title: videoDetails?.title,
                      }}
                    >
                      <Chip
                        icon={<Shortcut />}
                        label={"Share"}
                        onClick={() => {}}
                        // sx={{ marginInline: "1rem" }}
                      />
                    </RWebShare>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </div>
        ) : (
          <>
            <Stack>
              <Skeleton
                variant="text"
                width={"100%"}
                sx={{ fontSize: "1.5rem" }}
              />
            </Stack>
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", marginTop: 2 }}
            >
              <Grid container>
                <Grid item>
                  <Stack direction={"row"}>
                    <Skeleton
                      variant="circular"
                      width={56}
                      height={56}
                      sx={{ marginRight: 2 }}
                    />
                    <Stack direction={"column"}>
                      <Skeleton variant="text" width={150} />
                      <Skeleton variant="text" width={100} />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}
      </div>
    </>
  );
}

export default VideoPlayer;
