import { Card, CardHeader, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Comments from "../components/Comments/Comments";
import VideoPlayer from "../components/Player/VideoPlayer";
import VideosCard from "../components/video-card";
import {
  getChannelByID,
  getChannelVideos,
} from "../services/APIServices/channel.api";
import {
  getHomePageVideos,
  getVideoDetailsByID,
} from "../services/APIServices/videos.api";
import { useAppSelector } from "../store/hooks";
import { getUser } from "../store/reducers/user.reducer";
import { ChannelDetails } from "../types/channel";
import { VideoResponse } from "../types/medial";
import axios from "axios";

export const WatchPage = () => {
  let { videoID } = useParams();
  const [videoDetails, setVideoDetails] = useState<VideoResponse | null>(null);
  const [channel, setChannel] = useState<ChannelDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [suggestionVideos, setSuggestionVideos] = useState<
    VideoResponse[] | null
  >(null);
  const [channelVideos, setChannelVideos] = useState<VideoResponse[] | null>(
    null
  );
  const user = useAppSelector((state) => getUser(state));
  const navigate = useNavigate();
  const [cancelToken, setCancelToken] = useState<any>(null);
  const playerSide = useRef<any>(null);

  useEffect(() => {
    if (videoID && videoDetails?.videoID !== videoID) {
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      getVideoDetailsByID(videoID, cancelTokenSource.token).then((res: any) => {
        setVideoDetails(res);
        getChannelByID(res?.channelID as string, user?.userID).then(
          (res: any) => {
            setChannel(res);
          }
        );
      });
    } else if (!videoID) navigate(-1);

    return () => {
      setChannel(null);
      setVideoDetails(null);
      if (cancelToken) {
        cancelToken.cancel("Request cancelled");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoID]);
  useEffect(() => {
    getHomePageVideos({
      lowerLimit: 0,
      upperLimit: 10,
      userID: user?.userID,
      category: videoDetails?.category,
    })
      .then((response: any) => {
        if (response?.length >= 0) {
          setSuggestionVideos(
            response?.filter((video: VideoResponse) => video.videoID !== videoID));
        }
      })
      .catch((err) => console.error(err));

    return () => {
      setSuggestionVideos([]);
    };
  }, [videoDetails]);

  useEffect(() => {
    if (channel) {
      getChannelVideos(channel?.channelID).then((res: any) =>
        setChannelVideos(
          res?.filter((video: VideoResponse) => video.videoID !== videoID)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Container maxWidth={false}>
      <Grid container maxWidth={"100%"} spacing={1}>
        <Grid item xs={12} sm={12} lg={8} md={6} >
          <Stack direction={"column"} spacing={2} ref={playerSide}>
            <VideoPlayer
              videoDetails={videoDetails}
              setVideoDetails={setVideoDetails}
              channel={channel}
              setChannel={setChannel}
            />
            <Comments videoID={videoID} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} md={6}>
          {
            <div style={{backgroundColor:"rgba(256,256,256, 0.1)", borderRadius: "5px",height: playerSide?.current?.clientHeight, overflowY: "scroll"}}>
            <VideosCard
              small
              maxWidth={12}
              data={suggestionVideos as VideoResponse[]}
              isLoading={suggestionVideos === null}
            />
            </div>
          }
        </Grid>
      </Grid>
      <VideosCard
              small
              maxWidth={4}
              data={channelVideos as VideoResponse[]}
              isLoading={false}
            />
    </Container>
  );
};
