import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const ConfirmBtn = ({
  onConfirm,
  confirmationMessage,
  confirmButtonText,
  cancelButtonText,
  children
}:any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>{children}</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            {/* {confirmationMessage} */}
            Confirm Logout
        </DialogTitle>
        <DialogContent>
        {confirmationMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmBtn;
