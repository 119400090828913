import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { ChannelVideos } from "../../types/channel";


// Define the initial state using that type
const initialState: ChannelVideos | any = {};

export const channelVideosSlice = createSlice({
  name: "channelVideos",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setChannelVideos: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { setChannelVideos } = channelVideosSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUserChannelVideos = (state: RootState) => state?.channelVideos?.value;

export default channelVideosSlice.reducer;
