import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";


// Define the initial state using that type
const initialState: any = {isLoading: false, progress: 0};

export const loadingSlice = createSlice({
  name: "loading",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLoading: (state, action: PayloadAction<any>) => {
      console.log(action.payload)
      state.isLoading = action.payload.isLoading;
      state.progress = action.payload.progress;
    }
  },
});

export const { setLoading } = loadingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getLoading = (state: RootState) => state.loading;

export default loadingSlice.reducer;
