import React, { ReactNode, useEffect, useState } from 'react';
import { Snackbar, SnackbarContent, Button } from '@mui/material';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const ErrorFallback: React.FC<{ resetErrorBoundary: () => void }> = ({ resetErrorBoundary }) => (
  <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={true}>
    <SnackbarContent message="An error occurred. Please try again." action={
      <Button color="secondary" size="small" onClick={resetErrorBoundary}>
        Retry
      </Button>
    } />
  </Snackbar>
);

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = () => {
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const resetErrorBondary = () => {
    setHasError(false);
  }

  if (hasError) {
    // You can customize the fallback UI here
    return <ErrorFallback resetErrorBoundary={resetErrorBondary}/>;
  }

  return <>{children}</>;
};

export default ErrorBoundary

