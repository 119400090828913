import React, { useEffect, useState } from "react";
import { LogoutRounded } from "@mui/icons-material/";
import {
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  Paper,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { NAV_OPTIONS, PAGE_ROUTES } from "../../utils/constants";
import { colors } from "../../theme";
import { isMobile } from "react-device-detect";
import { useAuth } from "../../context/AuthContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function MiniDrawer(props: any) {
  const [open, setOpen] = useState(props.isMenuOpen);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false); // State for logout confirmation dialog

  const { logout } = useAuth();
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);

  const handleDrawerClose = () => {
    props.handleDrawerClose();
  };

  const handleLogoutConfirmation = () => {
    setLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    logout();
    setLogoutDialogOpen(false);
  };

  useEffect(() => {
    setOpen(props.isMenuOpen);
  }, [props.isMenuOpen]);

  useEffect(() => {
    console.log(pathname);
    if (pathname === "/") {
      setActiveTab(0);
    } else
      setActiveTab(
        NAV_OPTIONS.findIndex(
          ({ path }) => path !== PAGE_ROUTES.HOME && pathname.includes(path)
        )
      );
  }, [pathname]);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {/* <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton> */}
        {/* <IconButton onClick={handleDrawerClose}>
          <img src="assets/IndeeFlixLogo.svg" alt=""/>
        </IconButton> */}
      </DrawerHeader>
      <Divider />
      <List>
        {NAV_OPTIONS.map(({ text, path, icon }, index) => (
          <ListItem key={text} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate(path)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                color: activeTab === index ? colors.blueDark : "grey",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: activeTab === index ? colors.blueDark : "grey",
                }}
              >
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  opacity: open ? 1 : 0,
                  color: activeTab === index ? colors.blueDark : "grey",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {localStorage.getItem("token")?
        <>
          <Divider />
          <List>
            {[
              {
                text: "Logout",
                TextIcon: <LogoutRounded />,
                onClick: handleLogoutConfirmation,
              },
            ].map(({ text, TextIcon, onClick }) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                onClick={onClick}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {TextIcon}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>:null
      }
      {/* Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmLogout} color="primary">
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}

export const BottomNav = () => {
  const [value, setValue] = useState(0);

  let navigate = useNavigate();
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {NAV_OPTIONS.map(({ text, path, icon }, index) => (
          <BottomNavigationAction
            label={text}
            icon={<Icon>{icon}</Icon>}
            onClick={() => navigate(path)}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default function Navbar(props: any) {
  return isMobile ? null : <MiniDrawer {...props} />;
}
