import { createTheme } from '@mui/material/styles';

export const colors = {
  orangeDark: '#EA4B2E',
  orangeLight: '#FF9B3E',
  white: '#FAFAFA',
  greenDark: '#27732D',
  greenLight: '#8DB847',
  blueDark: '#0046AE'
}


const theme = createTheme({
  status: {
    danger: '#e53e3e',
    success: colors.greenLight
  },
  palette: {
    primary: {
      main: colors.blueDark,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.orangeDark,
      darker: colors.orangeLight,
    },
  },
});

export default theme;