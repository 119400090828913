import axios, { AxiosError, AxiosHeaders, AxiosPromise, AxiosResponse } from "axios";
const SERVER_URL: string = process.env.REACT_APP_SERVER_URL as string;
const validStatuses = [200, 201, 400, 204, 403];

export const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
  }
});

interface InstanceArgs {
  isPublic?:boolean
}
export class ApiHelper {
  isPublic: any;
  constructor(args?:InstanceArgs) {
    this.isPublic = args?.isPublic ? args?.isPublic : false;
  }

  post(uri: string, data: object): AxiosPromise {
    let url: string = "";
    url = uri;
    return axiosInstance
      .post(
        SERVER_URL + url,
        {...data},
        {
          headers: this.getHeaders(),
          withCredentials: false,
        }
      )
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  get(uri: string, params: object, cancelToken?:any): AxiosPromise {
    let url = uri;
    const config = {
      params,
      headers: this.getHeaders(),
      withCredentials: false,
      cancelToken: cancelToken
    };
    return axiosInstance
      .get(SERVER_URL + url, config)
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  upload(uri: string, data: object, onUploadProgress?:any): AxiosPromise {

    return axiosInstance
      .post(
        SERVER_URL + uri,
        { ...data },
        {
          headers: {
            ...this.getHeaders(),
            "Content-Type": "multipart/form-data",
          },
          withCredentials: false,
          onUploadProgress(progressEvent) {
              onUploadProgress?.(progressEvent);
          },
        }
      )
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  update(uri: string, data?: object): AxiosPromise {
    return axiosInstance
      .put(
        SERVER_URL + uri,
        { data },
        {
          headers: this.getHeaders(),
          withCredentials: false,
        }
      )
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  remove(uri: string): AxiosPromise {
    return axiosInstance
      .delete(SERVER_URL + uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  getHeaders(multipart = false): AxiosHeaders {
    let defaultHeaders: AxiosHeaders = new AxiosHeaders();
    //WRITE HERE THE HEADER TOKEN GENERATION LOGIC
    if (localStorage.getItem('token')) {
      defaultHeaders.setAuthorization(`Bearer ${localStorage.getItem('token') || ''}`)
    } 
    // else if(!this.isPublic) window.location.pathname = '/login'
    return defaultHeaders;
  }
  /*
   * Wraps error responses from the API
   * and returns `Promise.reject` with error
   */
  checkResponse(response: AxiosResponse) {
    if (validStatuses.includes(response.status)) {
      return response.data;
    }
    // If not authorized then reset authorization
    // and redirect to login page
    if (response.status === 401) {
      localStorage.clear();
      return Promise.reject(new Error("USER_ANONYMOUS"));
    } else {
      let err: any = new Error(response.statusText);
      err.response = response;
      return Promise.reject(err);
    }
  }

  handleError(error: any) {
    if (error.response && error.response.status) {
      let data = error.response.data;
      switch (error.response.status) {
        case 401:
          localStorage.clear();
          return;
        case 400:
          return;
        case 404:
          return;
        case 403:
          return;
        default:
          return;
      }
    } else if (error.response) {
      let err = new Error(error.response);
      return Promise.reject(err);
    } else {
      let err = new Error(error);
      return Promise.reject(err);
    }
  }
}
