import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  colors,
} from "@mui/material";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        color={"success"}
        variant={props.value ? "determinate" : "indeterminate"}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props?.value >= 0 ? (
          <Typography
            variant="caption"
            component="div"
            color={colors.lightGreen}
          >{`${Math.round(props.value)}%`}</Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
