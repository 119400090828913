import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import channelReducer from "./reducers/channel.reducer";
import userReducer from "./reducers/user.reducer";
import channelVideosReducer, { } from "./reducers/channelVideos.reducer";
import searchReducer from "./reducers/search.reducer";
import { persistReducer } from "redux-persist";
import messageReducer from "./reducers/message.reducer";
import loadingReducer from "./reducers/loading.reducer";

const reducers = combineReducers(
  {
    channel: channelReducer,
    channelVideos: channelVideosReducer,
    user: userReducer,
    search: searchReducer,
    message: messageReducer,
    loading: loadingReducer,
  }
);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['search', 'message', 'loading']
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
