import {
    Typography
} from "@mui/material";

const TaggedTypography = ({ children }) => {
    // Regular expression to find the tagged name
    const regex = /@(\w+)/g;

    // Function to parse and style the tagged name
    const renderText = (text) => {
        // Split the text by the tagged name
        const parts = text.split(regex);
        debugger
console.log('parts', parts)
        return parts.map((part, index) => {
            // Check if the part is a tagged name
            const isTaggedName = part.match(regex);
            if (isTaggedName) {
                // Apply styles to the tagged name
                return <Typography key={index} color={'primary'} sx={{ fontWeight: 600 }}>{`@${part}`}</Typography>;
            } else {
                return <Typography key={index}>{part}</Typography>;
            }
        });
    };

    return (
        <Typography variant="body1">
            {/* {renderText(children)} */}
            {children}
        </Typography>
    );
};

export default TaggedTypography;
