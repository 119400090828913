import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Autocomplete, Collapse, Skeleton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useRef, useState } from "react";
import { ChannelDetails, ChannelPayload } from "../../types/channel";
import { isMobile } from "react-device-detect";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { inscribeChannel } from "../../services/APIServices/user.api";
import { isUserChannel } from "../../utils";

interface ProfileCardProps {
  viewOnly?: boolean;
  channel: ChannelDetails | null;
  expanded?: boolean;
  isLoading?: boolean;
  edited?: boolean;
  isValidTag?: boolean;
  handleProfileImage?: (file: any, channelID?: string) => void;
  handleProfileChange?: (payload: any) => void;
  handleCreateChannel?: (channelDetails: ChannelDetails) => void;
  handleTagChange?: (tag: string) => void;
  handleExpansion?: (v: boolean) => void;
}

export default function ProfileCard({
  viewOnly = false,
  channel,
  expanded = false,
  isLoading,
  edited,
  isValidTag,
  handleProfileImage = () => {},
  handleProfileChange = () => {},
  handleCreateChannel = () => {},
  handleTagChange = () => {},
  handleExpansion = () => {},
}: ProfileCardProps) {
  // State variables
  const [avatarSrc, setAvatarSrc] = useState(channel?.profilePic);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [openFileSelector, setOpenFileSelector] = useState(false);
  const [isEditing, setIsEditing] = useState(edited); // State to track editing mode
  const [isExpanded, setExpanded] = useState(expanded);
  const [channelPayload, setChannelPayload] = useState<ChannelPayload | any>(
    {}
  );
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [subscriberCount, setSubscriberCount] = useState(
    channel?.subscriberCount ?? "-"
  );
  const avatarInputRef = useRef(null);
  let user = useAppSelector((state) => getUser(state));

  React.useEffect(() => {
    setAvatarSrc(channel?.profilePic);
    setChannelPayload({
      ...channel,
      channelName: channel?.name ?? "",
      categories: channel?.category ? [channel?.category] : [],
      channelDescription: channel?.channelDescription ?? "",
    });
    setSubscriberCount(channel?.subscriberCount ?? "-");
  }, [channel]);

  React.useEffect(() => {
    setExpanded(expanded);
    if (expanded && !channel) {
      setIsEditing(true);
    }
  }, [expanded, channel]);

  React.useEffect(() => {
    handleExpansion(isExpanded);
  }, [isExpanded, handleExpansion]);

  React.useEffect(() => {
    setIsEditing(edited);
  }, [edited]);

  const handleAvatarChange = (event: any) => {
    // Handle avatar image selection
    const file = event.target.files[0];
    if (file) {
      // const reader = new FileReader();

      // reader.onload = (e) => {
      //   setUploadedImage(e?.target?.result);
      // };
      // reader.readAsDataURL(file);
      const imageUrl = URL.createObjectURL(file);
      setAvatarSrc(imageUrl);
      setUploadedImage(file);
    }
    setOpenFileSelector(false);
    setIsEditing(true);
  };

  const onChange = (event: any, key: string) => {
    console.log("event", event);
    if (event && !channelPayload) {
      setChannelPayload({});
    }
    if (key === "categories")
      setChannelPayload((prev: any) => ({ ...prev, [key]: event }));
    else {
      setChannelPayload((prev: any) => ({
        ...prev,
        [key]: event.target.value,
      }));
      if (key === "channelTag") handleTagChange(event.target.value);
    }
  };

  const handleEditIconClick = () => {
    // Show file selector when edit icon is clicked
    setOpenFileSelector(true);
  };

  const handleFileSelectorBlur = () => {
    // Hide file selector when it loses focus
    setOpenFileSelector(false);
  };

  const handleEditButtonClick = () => {
    // Enable editing mode when Edit button is clicked
    setIsEditing(true);
  };

  const handleSaveButtonClick = () => {
    // Disable editing mode and make API call to update channel data when Save button is clicked
    if (channel) {
      setIsEditing(false);
      if (avatarSrc) {
        handleProfileImage(uploadedImage);
      }
      handleProfileChange(channelPayload);
    } else {
      handleCreateChannel(channelPayload);
    }
  };

  const handleAvatarMouseEnter = () => {
    // Show edit icon when mouse enters the avatar area
    setShowEditIcon(true);
  };

  const handleAvatarMouseLeave = () => {
    // Hide edit icon when mouse leaves the avatar area
    setShowEditIcon(false);
  };

  const handleInscribe = () => {
    inscribeChannel(channel?.channelID as string, user?.userID).then(
      (res: any) => {
        let status = res?.message?.split(" ")?.[0];
        if (status && channel) {
          setChannelPayload({
            ...channel,
            isSubscribed: status as string,
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false}>
        <Card
          sx={{
            width: isMobile ? "100%" : "90%",
            position: "relative",
            padding: "20px", // Adjust the padding as needed
            margin: "auto", // Adjust the margin as needed
            marginTop: isMobile ? 0 : "-80px",
            "@media (mx-width: 600px)": {
              position: "relative",
              maxWidth: "90%",
            },
          }}
        >
          <Collapse in={isExpanded} collapsedSize={isMobile ? 360 : 150}>
            <Box sx={{ flexGrow: 1 }}>
              <>
                <Grid
                  container
                  // display="flex"
                  // justifyContent="center"
                  // alignItems="center"
                  sx={
                    {
                      // marginLeft: "30px",
                      // flexDirection: "column",
                      // textAlign: "center",
                      // "@media (min-width: 600px)": {
                      //   flexDirection: "row",
                      //   textAlign: "initial",
                      // },
                    }
                  }
                >
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{ paddingTop: 2 }}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <label
                      htmlFor="avatar-input"
                      style={{ position: "relative" }}
                      onMouseEnter={handleAvatarMouseEnter}
                      onMouseLeave={handleAvatarMouseLeave}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          "@media (min-width: 600px)": {
                            display: "block",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        }}
                      >
                        {isLoading ? (
                          <Skeleton
                            variant="circular"
                            width={100}
                            height={100}
                          />
                        ) : (
                          <Avatar
                            alt="channel profile"
                            src={avatarSrc as string}
                            sx={{
                              width: "100px",
                              height: "100px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        {!viewOnly && showEditIcon && (
                          <Box
                            component="span"
                            onClick={handleEditIconClick}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                          >
                            <EditIcon sx={{ color: "#fff" }} />
                          </Box>
                        )}
                      </Box>
                      <input
                        id="avatar-input"
                        ref={avatarInputRef}
                        type="file"
                        accept="image/*"
                        style={{
                          display: openFileSelector ? "block" : "none",
                        }}
                        onBlur={handleFileSelectorBlur}
                        onChange={handleAvatarChange}
                        disabled={viewOnly}
                      />
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md
                    display={"flex"}
                    justifyContent={isMobile ? "center" : "flex-start"}
                  >
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{
                        // maxWidth: "300px",
                        marginLeft: "30px",
                        "@media (min-width: 600px)": {
                          marginLeft: "30px",
                          maxWidth: "none",
                        },
                      }}
                    >
                      {isLoading ? (
                        <Skeleton variant="text" />
                      ) : (
                        <TextField
                          id="channel-name"
                          label="Channel's Name"
                          variant="standard"
                          value={channelPayload?.channelName}
                          disabled={!isEditing} // Disable the field when not in editing mode
                          onChange={(e) => onChange(e, "channelName")}
                        />
                      )}
                      {isLoading ? (
                        <Skeleton variant="text" width={200} />
                      ) : (
                        <TextField
                          id="channel-tag"
                          label="Channel Tag"
                          variant="standard"
                          value={
                            channelPayload?.channelTag
                              ? `${!isEditing ? "@" : ""}${
                                  channelPayload?.channelTag
                                }`
                              : channelPayload?.channelTag
                          }
                          disabled={!isEditing} // Disable the field when not in editing mode
                          onChange={(e) => onChange(e, "channelTag")}
                          error={!viewOnly && !isValidTag}
                          helperText={
                            viewOnly || isValidTag
                              ? ""
                              : "Tag is not available!"
                          }
                        />
                      )}
                      {isExpanded ? (
                        <>
                          <TextField
                            multiline
                            id="desc-tag"
                            label="Channel Description"
                            variant="standard"
                            value={channelPayload?.channelDescription}
                            disabled={!isEditing} // remove true to enable
                            onChange={(e) => onChange(e, "channelDescription")}
                          />
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-standard"
                            options={[]}
                            value={channelPayload?.categories}
                            onChange={(event, newValue) => {
                              onChange(newValue, "categories");
                            }}
                            disabled={!isEditing}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Categories"
                                placeholder="Categories"
                              />
                            )}
                          />
                          <TextField
                            id="contact-tag"
                            label="Contact Details"
                            variant="standard"
                            value={channelPayload?.contactDetails}
                            disabled={!isEditing} // Disable the field when not in editing mode
                            onChange={(e) => onChange(e, "contactDetails")}
                          />
                        </>
                      ) : null}
                      {isLoading || !channel ? null : (
                        <Stack>
                          {
                            <ExpandMoreOutlinedIcon
                              onClick={() => setExpanded(!isExpanded)}
                              fontSize="medium"
                              sx={{
                                rotate: isExpanded ? "180deg" : "0deg",
                                position: "absolute",
                                top: "15px",
                                right: "15px",
                                zIndex: 2,
                                cursor: "pointer",
                                padding: "5px",
                                border: "1px solid currentColor", // Apply border with current color
                                borderRadius: "50%",
                              }}
                            />
                          }
                        </Stack>
                      )}
                      {isLoading ? null : (
                        <Stack
                          direction="column"
                          spacing={1}
                          justifyContent="flex-end"
                          sx={{
                            position: "relative",
                            right: "0",
                            bottom: "0",
                            marginTop: "20px",
                            "@media (min-width: 600px)": {
                              position: "absolute",
                              right: "20px",
                              bottom: "20px",
                              marginTop: "0",
                            },
                          }}
                        >
                          {!viewOnly &&
                            !isEditing && ( // Show the "Edit" button only when not in editing mode
                              <Button
                                variant="outlined"
                                onClick={handleEditButtonClick}
                              >
                                Edit
                              </Button>
                            )}
                          {isEditing && ( // Show the "Save" button only in editing mode
                            <Button
                              variant="outlined"
                              onClick={handleSaveButtonClick}
                            >
                              {expanded ? "Create" : "Save"}
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md
                    display={"flex"}
                    justifyContent={isMobile ? "center" : "left"}
                    alignItems={"center"}
                  >
                    <Stack direction={"column"} gap={2}>
                      {isEditing ||
                      isExpanded ||
                      isLoading ||
                      !channel ? null : (
                        <Chip
                          size="small"
                          color="secondary"
                          label={`Subscriber Count: ${subscriberCount}`}
                        />
                      )}
                      {viewOnly && !isLoading ? (
                        <Chip
                          size="small"
                          color="secondary"
                          label={`${
                            (typeof channel?.isSubscribed === "boolean" &&
                              typeof channel?.isSubscribed) ||
                            channel?.isSubscribed === "SUBSCRIBED"
                              ? "Unsubscribe"
                              : "Subscribe"
                          }`}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleInscribe()}
                          disabled={isUserChannel(user?.channelList, channel?.channelID)}
                        />
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            </Box>
          </Collapse>
        </Card>
      </Container>
    </React.Fragment>
  );
}
