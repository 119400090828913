// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DatePickerStyles.css */

.react-datepicker-wrapper {
    display: block;
    width: 100%;
  }
  
  .custom-date-picker {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 14px;
  }
  
  .custom-date-picker:focus {
    outline: none;
    border-color: #2d88ff;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,cAAc;IACd,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,wCAAwC;IACxC,cAAc;IACd,YAAY;IACZ,WAAW;IAEX,oCAAoC;IAEpC,wBAAwB;IACxB,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,qBAAqB;EACvB","sourcesContent":["/* DatePickerStyles.css */\r\n\r\n.react-datepicker-wrapper {\r\n    display: block;\r\n    width: 100%;\r\n  }\r\n  \r\n  .custom-date-picker {\r\n    font: inherit;\r\n    letter-spacing: inherit;\r\n    color: currentColor;\r\n    padding: 4px 0 5px;\r\n    border: 0;\r\n    box-sizing: content-box;\r\n    background: none;\r\n    height: 1.4375em;\r\n    margin: 0;\r\n    -webkit-tap-highlight-color: transparent;\r\n    display: block;\r\n    min-width: 0;\r\n    width: 100%;\r\n    -webkit-animation-name: mui-auto-fill-cancel;\r\n    animation-name: mui-auto-fill-cancel;\r\n    -webkit-animation-duration: 10ms;\r\n    animation-duration: 10ms;\r\n    padding: 16.5px 14px;\r\n  }\r\n  \r\n  .custom-date-picker:focus {\r\n    outline: none;\r\n    border-color: #2d88ff;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
