import styled from "@emotion/styled";
import { Send } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  IconButtonProps,
  InputAdornment,
  ListItem,
  Skeleton,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";
import { memo, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  List as CommentList,
  // ListChildComponentProps,
} from "react-virtualized";
import { getUserDetails } from "../../services/APIServices/user.api";
import {
  addComments,
  addReply,
  getComments,
  getReplies,
} from "../../services/APIServices/videos.api";
import { useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/reducers/user.reducer";
import { colors } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import TaggedTypography from "../CustomComponent/TaggedTypograpgy";
import { ListOnScrollProps } from "react-window";
interface CommentsProps {
  videoID: string | undefined;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme?: Theme; expand: boolean }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme?.transitions?.create("transform", {
    duration: theme?.transitions?.duration?.shortest,
  }),
}));

const commentLimit = 3;

export default function Comments({ videoID }: CommentsProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(!isMobile);
  const [comments, setComments] = useState<any[]>([]);
  const usersDetails = useRef<any>(null);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [commentInput, setCommentInput] = useState("");
  const [buttonColor, setButtonColor] = useState<any>("default");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [itemCount, setItemCount] = useState<number>(0);
  const [rowHeight, setRowHeight] = useState<number[]>([]);
  const [replyInput, setReplyInput] = useState<any>(null);
  const [replies, setReplies] = useState<any>({});
  const user = useAppSelector((state) => getUser(state));
  const inputRef = useRef<any>(null);
  const listContainer = useRef<any>(null);
  const { verifyAuth } = useAuth();
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (videoID) {
      getComments(
        videoID,
        comments?.length,
        comments?.length + commentLimit
      ).then((res: any) => {
        if (res?.length >= commentLimit + 1) {
          setHasNextPage(true);
        }
        setComments(res);
        setLowerLimit(res.length);
        setIsLoading(false);
      });
    }
    return () => {
      setComments([]);
      setLowerLimit(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoID]);

  useEffect(() => {
    if (lowerLimit === comments.length) {
      setItemCount(comments.length);
      setRowHeight(
        comments.map(
          ({ repiesCount, index }) =>
            80 + repiesCount * 80 + (index === 0 ? 80 : 0)
        )
      );
      let userSet: any = new Set(comments?.map(({ userID }) => userID));
      for (let userID of userSet) {
        if (!userID || usersDetails?.current?.userID) continue;
        getUserDetails(userID).then((res: any) => {
          usersDetails.current
            ? (usersDetails.current[userID] = res)
            : (usersDetails.current = { [userID]: res });
        });
      }
    }
  }, [lowerLimit, comments]);

  useEffect(() => {
    if (
      replyInput &&
      (commentInput?.length === 0 ||
        commentInput?.split(" ")?.[0] !== replyInput?.taggedUser)
    ) {
      setReplyInput(null);
      inputRef.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentInput]);

  const handleComment = () => {
    if (commentInput && videoID) {
      if (replyInput && replyInput?.commentID)
        addReply(
          commentInput,
          user?.userID,
          videoID,
          replyInput?.commentID
        ).then((res: any) => {
          setReplies((prev: any) => ({ ...prev, res }));
          setRowHeight(
            comments.map(
              ({ repiesCount, index }) =>
                80 + repiesCount * 80 + (index === 0 ? 80 : 0)
            )
          );
        });
      else
        addComments(commentInput, user?.userID, videoID).then((res: any) =>
          getComments(
            videoID,
            comments?.length,
            comments?.length + commentLimit
          ).then((res: any) => {
            let newComments: any[] = comments;
            newComments.splice(comments?.length - 1, 0, ...res);
            setComments(newComments);
          })
        );
      inputRef.current.value = "";
    }
  };

  const isItemLoaded = (index: number) =>
    !hasNextPage || index < comments.length;

  const loadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      setLowerLimit(comments.length);
      if (videoID && comments?.length > commentLimit) {
        getComments(videoID, comments?.length, comments?.length + commentLimit)
          .then((res: any) => {
            if (!res || res?.length < commentLimit + 1) {
              setHasNextPage(false);
            }
            let newComments: any[] = comments;
            if (res?.length > 0) newComments?.push(...res);
            setComments(newComments);
            setLowerLimit(comments.length);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      }
    }
  };

  const onItemsRendered = ({
    overscanStartIndex,
    overscanStopIndex,
    visibleStartIndex,
    visibleStopIndex,
  }: any) => {
    if (
      isItemLoaded(visibleStopIndex - 1) &&
      overscanStopIndex === comments?.length - 1
    ) {
      loadMore();
      setRowHeight(
        comments.map(
          ({ repiesCount, index }) =>
            80 + repiesCount * 80 + (index === 0 ? 80 : 0)
        )
      );
    }
  };

  const getItemSize = (index: number) => rowHeight[index];

  const ReplyContainer = (props: any) => {
    const { data, parentID, key } = props;
    const cmt: any = data;
    const usr: any = usersDetails?.current?.[cmt?.userID];
    const handleReply = () => {
      inputRef?.current?.focus();
      inputRef.current.value = `@${cmt?.userName} `;
      setReplyInput({
        commentID: parentID,
        userID: user?.userID,
        videoID,
        taggedUser: `@${cmt?.userName}`,
      });
    };
    return cmt ? (
      // <ListItem style={style} key={index} component="div" disablePadding>
      <Stack direction={"row"} sx={{ paddingBlock: 1 }} key={key}>
        <Avatar
          alt={""}
          src={usr?.profilePic as string}
          sx={{ width: 50, height: 50, marginRight: 2 }}
        />
        <Stack>
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <Typography variant="subtitle2">
              {usr ? `${usr?.firstName} ${usr?.lastName}` : `${cmt?.userName}`}
            </Typography>
            <Typography
              variant="caption"
              color={"GrayText"}
              sx={{ lineHeight: "1.57" }}
            >
              &nbsp;
              {`• ${moment(
                `${cmt?.creationTime?.split("T")?.join(" ")} UTC`
              ).fromNow()}`}
            </Typography>
          </Stack>
          <TaggedTypography>{cmt?.message}</TaggedTypography>
          <Typography
            variant="caption"
            color={"GrayText"}
            sx={{ cursor: "pointer" }}
            onClick={() => handleReply()}
          >
            {"Reply"}
          </Typography>
        </Stack>
      </Stack>
    ) : (
      // </ListItem>
      <Stack direction={"row"} sx={{ alignItems: "center", marginTop: 2 }}>
        <Grid container>
          <Grid item>
            <Stack direction={"row"}>
              <Skeleton
                variant="circular"
                width={56}
                height={56}
                sx={{ marginRight: 2 }}
              />
              <Stack direction={"column"}>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={100} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const commentContainer = (props: any) => {
    const { index, style } = props;
    const cmt: any = comments?.[index];
    const usr: any = usersDetails?.current?.[cmt?.userID];
    const handleReply = (commentID: string) => {
      inputRef?.current?.focus();
      inputRef.current.value = `@${cmt?.userName} `;
      setReplyInput({
        commentID,
        userID: user?.userID,
        videoID,
        taggedUser: `@${cmt?.userName}`,
      });
    };
    if (cmt?.repiesCount && !replies?.[cmt?.commentID]) {
      getReplies(cmt?.commentID, 0, cmt?.repiesCount).then((res: any) =>
        setReplies((prev: any) => ({ ...prev, [cmt.commentID]: res }))
      );
    }
    return cmt ? (
      <ListItem style={style} key={index} component="div" disablePadding>
        <Stack direction={"row"} sx={{ paddingBlock: 2 }}>
          <Avatar
            alt={""}
            src={usr?.profilePic as string}
            sx={{ width: 50, height: 50, marginRight: 2 }}
          />
          <Stack>
            <Stack direction={"row"} sx={{ alignItems: "center" }}>
              <Typography variant="subtitle2">
                {usr
                  ? `${usr?.firstName} ${usr?.lastName}`
                  : `${cmt?.userName}`}
              </Typography>
              <Typography
                variant="caption"
                color={"GrayText"}
                sx={{ lineHeight: "1.57" }}
              >
                &nbsp;
                {`• ${moment(
                  `${cmt?.creationTime?.split("T")?.join(" ")} UTC`
                ).fromNow()}`}
              </Typography>
            </Stack>
            <Typography variant="body1">{cmt?.message}</Typography>
            <Typography
              variant="caption"
              color={"GrayText"}
              sx={{ cursor: "pointer" }}
              onClick={() => handleReply(cmt?.commentID as string)}
            >
              {"Reply"}
            </Typography>
            <Collapse
              sx={{
                borderLeft: "2px solid",
                borderColor: colors.orangeDark,
                paddingLeft: 1,
              }}
              in={replies?.[cmt?.commentID]?.length > 0}
              unmountOnExit
            >
              {/* <ReplyList
                                height={replies?.[cmt?.commentID]?.length * 100}
                                itemCount={replies?.[cmt?.commentID]?.length}
                                itemSize={90}
                                width={"100%"}
                                itemData={replies?.[cmt?.commentID]}
                                overscanCount={1}
                            // onItemsRendered={onItemsRendered}
                            >
                                {replyContainer}
                            </ReplyList> */}
              {(replies?.[cmt?.commentID]?.length
                ? replies?.[cmt?.commentID]
                : Array(cmt?.repiesCount)?.fill({})
              )?.map((data: any, index: number) => (
                <ReplyContainer
                  key={index}
                  parentID={cmt?.commentID}
                  data={data}
                  index={index}
                />
              ))}
            </Collapse>
          </Stack>
        </Stack>
      </ListItem>
    ) : null;
  };

  return (
    <>
      <Card>
        <CardHeader
          title={"Comments"}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {comments?.length ? <ExpandMoreIcon /> : null}
            </ExpandMore>
          }
        />
        {comments?.length ? (
          <Collapse in={expanded} unmountOnExit>
            <CardContent>
              <div ref={listContainer} style={{ width: "100%" }}>
                <CommentList
                  height={250}
                  rowCount={itemCount}
                  rowHeight={({ index }) => {
                    let v = getItemSize(index);
                    console.log("rowHeight", rowHeight);
                    console.log("itemCount", itemCount);
                    console.log("v", v);
                    return v;
                  }}
                  width={listContainer?.current?.innerWidth ?? 300}
                  onItemsRendered={onItemsRendered}
                  rowRenderer={commentContainer}
                  onScroll={({
                    scrollTop,
                    scrollHeight,
                    clientHeight,
                  }: any) => {
                    if (scrollTop + clientHeight === scrollHeight) {
                      loadMore();
                    }
                  }}
                />
              </div>
            </CardContent>
          </Collapse>
        ) : (
          <Stack sx={{ textAlign: "center" }}>
            <Typography variant="h6">You are the first person!</Typography>
            <Typography
              variant="button"
              color={colors.blueDark}
              onClick={() =>
                user?.userID ? inputRef?.current?.focus() : verifyAuth()
              }
              sx={{ cursor: "pointer" }}
            >
              {!user?.userID ? (
                <span style={{ cursor: "pointer", color: colors.blueDark }}>
                  Login to
                </span>
              ) : (
                ""
              )}{" "}
              Add a comment
            </Typography>
          </Stack>
        )}
        {comments?.length || user?.userID ? (
          <CardActionArea sx={{ padding: 1 }}>
            {user?.userID ? (
              <FormControl fullWidth variant="filled">
                <FilledInput
                  inputRef={inputRef}
                  multiline
                  maxRows={2}
                  color="primary"
                  margin="dense"
                  placeholder="Add Comment"
                  onChange={(e) => setCommentInput(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color={buttonColor}
                        onMouseEnter={() => {
                          if (commentInput) setButtonColor("primary");
                        }}
                        onMouseLeave={() => setButtonColor("default")}
                      >
                        <Send onClick={() => handleComment()} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            ) : (
              <Stack
                direction={"row"}
                sx={{ textAlign: "center" }}
                justifyContent={"center"}
              >
                <Typography variant="button">
                  Please{" "}
                  <span
                    onClick={() => verifyAuth()}
                    style={{ cursor: "pointer", color: colors.blueDark }}
                  >
                    Login
                  </span>{" "}
                  to add a comment
                </Typography>
              </Stack>
            )}
          </CardActionArea>
        ) : null}
      </Card>
    </>
  );
}
