import React from "react";
import Button from "@mui/material/Button";
import { Box, Stack } from "@mui/material";
const suggestedCategories = [
  { tags: "Comedy" },
  { tags: "Gaming" },
  { tags: "Music" },
  { tags: "Education" },
  { tags: "Entertainment" },
  { tags: "Video/Animation" },
];
function getSuggestionsTab(props: {
  selectedCategory: string | any;
  setSelectedCategory: (p: any) => void;
}) {
  const { selectedCategory, setSelectedCategory } = props;
  return (
    <div>
      <Box
        sx={{
          "& button": {
            marginBottom: "10px",
            marginTop: "10px",
            marginRight: "15px",
            color: "primary",
          },
        }}
      >
        <div>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setSelectedCategory(null)}
          >
            All
          </Button>
          {suggestedCategories.map((v) => 
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setSelectedCategory(v.tags)}
            >
              {v.tags}
            </Button>
          )}
        </div>
      </Box>
    </div>
  );
}

export default getSuggestionsTab;
