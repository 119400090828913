import "./CreateChannel.css";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import Channel from "./Channel";

function CreateChannel() {
  const [createChannel, setCreateChannel] = useState(false);
  return (
    <div className="center-screen">
      {createChannel ? (
        <Channel create />
      ) : (
        <Box>
          <Typography variant="h6" color={"primary"}>Your Channel does not exists!</Typography>
          <Button onClick={() => setCreateChannel(true)} variant="contained">
            Create Your Channel
          </Button>
        </Box>
      )}
    </div>
  );
}

export default CreateChannel;
