export const AppName = "IndeeFlix";
export const primaryColor = "blue";

export const channelProfileTabMenu = [
  { title: "Home", viewOnly: true },
  // { title: "Playlist", viewOnly: true  },
  { title: "Upload Video", viewOnly: false  },
];

export const PAGE_ROUTES = {
  HOME: "/",
  CHANNEL_PAGE: "/channel",
  PROFILE_PAGE: "/profile",
  SEARCH_PAGE: "/search",
  PLAYER_PAGE: "/watch",
  LOGIN_PAGE: "/login"
};

export const ROUTES_PATH = {
  CHANNEL_LANDING: `${PAGE_ROUTES.CHANNEL_PAGE}`,
  CHANNEL_PROFILE: `${PAGE_ROUTES.CHANNEL_PAGE}/profile`,
  CREATE_CHANNEL: `${PAGE_ROUTES.CHANNEL_PAGE}/create`,
  EDIT_CHANNEL: `${PAGE_ROUTES.CHANNEL_PAGE}/edit`,
  SEARCH: `${PAGE_ROUTES.SEARCH_PAGE}`,
  PLAYER: `${PAGE_ROUTES.PLAYER_PAGE}/:videoID`,
  LOGIN: `${PAGE_ROUTES.LOGIN_PAGE}`,
  USER_PROFILE: `${PAGE_ROUTES.PROFILE_PAGE}`,
};

export const NAV_OPTIONS = [
  { text: "Home", icon: "home", path: PAGE_ROUTES.HOME },
  {
    text: "Channel",
    path: PAGE_ROUTES.CHANNEL_PAGE,
    icon: "podcasts",
  },
  {
    text: "Profile",
    path: PAGE_ROUTES.PROFILE_PAGE,
    icon: "account_circle_rounded",
  },
];

export const videoUpperLimit = 10;